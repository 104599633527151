import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
    Ionicons,
    MaterialIcons,
    MaterialCommunityIcons,
  } from "@expo/vector-icons";
import { api } from "../../utils/Api";
let myModal: any;
export default function ModalUserDetails(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value: "",

  });

  const [user,setUser] = useState([])
const [visible_content,setVisibleContent] = useState(false)

 




  useEffect(() => {
    let myModal1: any = document.getElementById("user");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      getUser({ id: props.id });
      myModal = new bootstrap.Modal(
        document.getElementById("user"),
        {}
      );
      myModal.show();
   
    }
  }, [props.open]);

  async function getUser(get_data: any = {}) {
    let pass_data = {
      get: {
        user_id : get_data.id
      }
    };
    let data_res = await api("/settings/superapp_users", pass_data);

  
    if (data_res.status_code == 200) {
      if (data_res?.response?.data) {
       console.log(data_res?.response?.data)
          setUser(data_res.response.data);
      
      }
 
      setVisibleContent(true);
    }
  }

  const handleCall = (e,fullMobile) => {
    e.stopPropagation()
    if (fullMobile) {
      window.location.href = `tel:${fullMobile}`;
    }
  };

  const handleEmail = (e,email) => {
    e.stopPropagation()
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };


  return (
    <div
      className="modal fade"
      id="user"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content seller-choose-modal">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">Customer Details</h6>
        
          </div>
          <div className="modal-body" id="ChooseBrandOutletBody">
    
            {props.open && (
           
                // visible_content ? (
                  user.length != 0 ? (
                    user?.map((ord_ele: any, ord_index: any) => (
                        <div className="card p-12 mb-2" >
                        <div className="d-flex border-bottom pb-2 mb-2">
                          <div>
                            <div className="pb-3 d-flex">
                              {ord_ele.hasOwnProperty("profile_picture") && ord_ele.profile_picture != null ? (
                                <div className="pe-2">
                                  <img
                                    src={ord_ele.profile_picture}
                                    alt="Profile"
                                    className="rounded-float-start"
                                    height="34"
                                    width="34"
                                    style={{ borderRadius: "50px" }}
                                  />
                                </div>
                              ) : (
                                <div className="pe-2">
                                  <img
                                    src={require("../../assets/icon/avatar.png")}
                                    className="rounded-float-start"
                                    height="34"
                                    width="34"
                                  />
                                </div>
                              )}
                              <div>
                                <p className="text-dark fw-bold fs-7">{ord_ele?.name}</p>
                                <p>{ord_ele?.user_name}</p>
                              </div>
                            </div>
                      
                            <div>
                              Demo Order User: <span className="fw-500">{ord_ele.demo_order ? "Yes" : "No"}</span>
                            </div>
                            <div>
                              COD: <span className="fw-500">{ord_ele.cod_allowed === "force_allowed" ? "force allowed" : ord_ele.cod_allowed}</span>
                            </div>
                            <div>
                              Mobile: <span className="fw-500">{ord_ele.full_mobile}</span>
                            </div>
                            <div>
                              Blood Group: <span className="fw-500">{ord_ele.blood_group}</span>
                            </div>
                            <div>
                              Gender: <span className="fw-500">{ord_ele.gender}</span>
                            </div>
                            <div>
                              Date of Birth: <span className="fw-500">{ord_ele.dob}</span>
                            </div>
                            <div>
                              Address: <span className="fw-500">{ord_ele.address}</span>
                            </div>
                            <div>
  Height: <span className="fw-500">{ord_ele.height}</span>
</div>
<div>
  Weight: <span className="fw-500">{ord_ele.weight}</span>
</div>
<div>
  Pincode: <span className="fw-500">{ord_ele.pincode}</span>
</div>
<div>
  Referred User ID: <span className="fw-500">{ord_ele.referred_user_id}</span>
</div>
<div>
  Default App: <span className="fw-500">{ord_ele.default_app}</span>
</div>
<div>
  Signup Platform: <span className="fw-500">{ord_ele.signup_platform}</span>
</div>
<div>
  User Status: <span className="fw-500">{ord_ele.user_status}</span>
</div>
<div>
  Profile Verified: <span className="fw-500">{ord_ele.profile_verified ? 'Yes' : 'No'}</span>
</div>
<div>
  Demo Order: <span className="fw-500">{ord_ele.demo_order ? 'Yes' : 'No'}</span>
</div>
<div>
  COD Allowed: <span className="fw-500">{ord_ele.cod_allowed}</span>
</div>

                          </div>
                      
                          <div className="ms-auto text-end">
                            <div className="mb-1">{ord_ele?.created_date}</div>
                            <div>
                              ID: <span className="fw-500">{ord_ele?.id}</span>
                            </div>
                            <div>{ord_ele?.user_status}</div>
                      
                            <div className="d-flex end">
                              <div className="call-btn m-2" onClick={(e) => handleCall(e, ord_ele?.full_mobile)} style={{ cursor: 'pointer' }}>
                                <Ionicons name="md-call" size={18} color="#A80032" />
                              </div>
                              {ord_ele?.email && (
                                <div className="call-btn" onClick={(e) => handleEmail(e, ord_ele?.email)} style={{ cursor: 'pointer' }}>
                                  <Ionicons name="md-mail" size={18} color="#A80032" />
                                </div>
                              )}
                            </div>
                          </div>
                          
                        </div>
                        <b>Statistics :</b>
<div className="row">
  <div className="col-lg-3 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Orders</div>
      <div className="text-end fw-bold">{ord_ele?.statistics?.total_orders}</div>
    </div>
  </div>
  
  <div className="col-lg-3 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">Total Order Value</div>
      <div className="text-end fw-bold">{ord_ele?.statistics?.total_orders_value}</div>
    </div>
  </div>
  <div className="col-lg-3 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">Average Order Value</div>
      <div className="text-end fw-bold">{ord_ele?.statistics?.average_orders_value}</div>
    </div>
  </div>
  
  
  <div className="col-lg-3 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">Last Order Date</div>
      <div className="text-end fw-bold">{ord_ele?.statistics?.last_order_date}</div>
    </div>
  </div>
  
  <div className="col-lg-3 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">First Order Date</div>
      <div className="text-end fw-bold">{ord_ele?.statistics?.first_order_date}</div>
    </div>
  </div>
  
  <div className="col-lg-3 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">Signup to First Order Difference</div>
      <div className="text-end fw-bold">{ord_ele?.statistics?.signup_first_order_difference}</div>
    </div>
  </div>

  <div className="col-lg-3 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">Average Interval Between Orders</div>
      <div className="text-end fw-bold">{ord_ele?.statistics?.average_interval_between_orders}</div>
    </div>
  </div>
</div>
<b>Ecommerce Remarks:</b>
{ord_ele?.ecommerce_remarks.length>0?
<ul>
  <div>
    {ord_ele?.ecommerce_remarks.map((remark, i) => (
      <li key={i}>
        <p>{remark.message}</p>
        <p>{remark.create_time}</p>
      </li>
    ))}
  </div>
</ul> :<div> -</div>}
<b> Ecommerce Cart Response:{" "}</b>
                                <ul>
                                  <div>{ord_ele?.ecommerce_cart_response.map((ele, i) => (

                                    <li>{ele.response}<p>{ele.notes}</p><p>{ele.create_time}</p></li>
                                  ))}</div>
                                </ul>
                      </div>
                    ))
                  ) : (
                    <div className="center p-3">
                      <div className="card p-3 w-100">
                        <div className="w-100 text-center">
                          <img
                            src={require("../../assets/img/not-found.png")}
                            className="w-20"
                          />
                        </div>
                        <p className="center pt-3">No Details</p>
                      </div>
                    </div>
                  )
                ) 
            //     : (
            //       [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele: any) => (
            //         <div className="card mb-2 p-3">
            //           <Skeleton height={10} width={80} />
            //         </div>
            //       ))

            //     )
           
            // )
            }


          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
